import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore, database } from './firebase';
import { collection, query, where, onSnapshot, orderBy, doc, setDoc, updateDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import { ref, onValue, set } from 'firebase/database';
import ChatSidebar from './ChatSidebar';
import ChatWindow from './ChatWindow';
import MessageInput from './MessageInput';
import MessageInput2 from './MessageInput2';
import Settings from './Settings';
import { ChatModel, getChatModelByName } from './chatModels';
import Toolbar from './Toolbar';
import { ThemeProvider } from './ThemeContext';
import GoogleAd from './GoogleAd';
import { Menu, X } from 'lucide-react'; // Import icons for mobile menu
import MobileToolbar from './MobileToolbar';
import { FontProvider } from './FontContext';

const LoadingScreen = () => {
  return (
    <div className="fixed inset-0 bg-[rgb(229,229,225)] dark:bg-[rgb(24,24,24)] z-50 flex items-center justify-center">
      <div className="flex flex-col items-center space-y-4">
        <div className="w-12 h-12 border-4 border-primary border-t-transparent rounded-full animate-spin" />
        <p className="text-lg text-gray-600 dark:text-gray-300">Loading your workspace...</p>
      </div>
    </div>
  );
};


const MainChatInterface = () => {
  const [chatTitle, setChatTitle] = useState('New Chat');
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState({});
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState(Object.values(ChatModel)[0]?.modelString || 'gpt-4o-mini');
  const [selectedModelName, setSelectedModelName] = useState(Object.values(ChatModel)[0]?.name || 'GPT 4o mini');
  const [hasSubscription, setHasSubscription] = useState(false);
  const [hasBasicSubscription, setHasBasicSubscription] = useState(false);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isCheckingSubscription, setIsCheckingSubscription] = useState(true);
  const [messageLimit, setMessageLimit] = useState(0);
  const [reasoningStream, setReasoningStream] = useState(''); // track partial chain-of-thought

  const navigate = useNavigate();
  const firstRun = useRef(true);

  const handleReasoningStreamUpdate = (partialReasoning) => {
    setReasoningStream(partialReasoning);
  };

  const checkSubscription = useCallback(async (uid) => {
    try {
      setIsCheckingSubscription(true);
      const revenueCatEndpoint = 'https://arrow-ai-subscription-cloud-run-123734116924.us-east1.run.app/api/check-subscription-revenuecat';
      const stripeEndpoint = 'https://arrow-ai-subscription-cloud-run-123734116924.us-east1.run.app/api/check-subscription-stripe';
      const specialUsersEndpoint = 'https://arrow-ai-subscription-cloud-run-123734116924.us-east1.run.app/api/check-subscription-special-users';
      const stripeBasicEndpoint = 'https://arrow-ai-subscription-cloud-run-123734116924.us-east1.run.app/api/check-subscription-stripe-basic';

      const fetchSubscription = async (endpoint) => {
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uid }),
        });
        return response.json();
      };

      const [revenueCatData, stripeData, specialData, stripeBasicData] = await Promise.all([
        fetchSubscription(revenueCatEndpoint),
        fetchSubscription(stripeEndpoint),
        fetchSubscription(specialUsersEndpoint),
        fetchSubscription(stripeBasicEndpoint)
      ]);

      const hasActiveSubscription = revenueCatData.hasSubscription || stripeData.hasSubscription || specialData.hasSubscription;
      const hasBasicSubscription = stripeBasicData.hasSubscription;

      setHasSubscription(hasActiveSubscription);
      setHasBasicSubscription(hasBasicSubscription);
    } catch (error) {
      console.error('Error checking subscription:', error);
      setHasSubscription(false);
      setHasBasicSubscription(false);
    } finally {
      setIsCheckingSubscription(false);
    }
  }, []);


  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth <= 768;
      setIsMobile(newIsMobile);
      setIsSidebarOpen(false);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it initially
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    const user = auth.currentUser;
    if (user && firstRun.current) {
      firstRun.current = false;
      checkSubscription(user.uid);
    }
  }, [checkSubscription]);


  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      navigate('/');
      return;
    }
    const chatsRef = collection(firestore, 'chats');
    const q = query(
      chatsRef,
      where('owner', '==', user.uid),
      orderBy('lastMessageSent', 'desc')
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedChats = querySnapshot.docs.map(doc => {
        const data = doc.data();
        const lastMessageSent = data.lastMessageSent ? data.lastMessageSent.toDate() : new Date();
        return {
          id: doc.id,
          ...data,
          lastMessageTimeAgo: getTimeAgo(lastMessageSent)
        };
      });
      setChats(fetchedChats);
      setLoading(false);
    }, (error) => {
      console.error("Error fetching chats:", error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (selectedChat) {
      const chatModel = getChatModelByName(selectedChat.model);

      setSelectedModel(chatModel.modelString);
      setSelectedModelName(chatModel.name);
      setMessageLimit(chatModel.messageLimit);
    } else {
      const defaultModel = Object.values(ChatModel)[0];

      setSelectedModel(defaultModel.modelString);
      setSelectedModelName(defaultModel.name);
      setMessageLimit(defaultModel.messageLimit);
    }
  }, [selectedChat]);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userRef = ref(database, `users/${user.uid}`);
      onValue(userRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData) {
          setUserName(userData.fullName || '');
          setUserEmail(userData.email || '');
        }
      });
    }
  }, []);

  const getTimeAgo = (date) => {
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);
    const timeUnits = [
      { value: 31536000, unit: 'year' },
      { value: 2592000, unit: 'month' },
      { value: 86400, unit: 'day' },
      { value: 3600, unit: 'hour' },
      { value: 60, unit: 'minute' },
      { value: 1, unit: 'second' },
    ];

    for (const { value, unit } of timeUnits) {
      const difference = Math.floor(diffInSeconds / value);
      if (difference >= 1) {
        return `${difference} ${unit}${difference > 1 ? 's' : ''} ago`;
      }
    }
    return 'Just now';
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleChatSelect = (chatId) => {
    const selected = chats.find(chat => chat.id === chatId);
    setSelectedChat(selected);
    setChatTitle(selected.topic || 'New Chat');
    setShowSettings(false);
    if (isMobile) {
      setIsSidebarOpen(false);
    }
    // Fetch messages for the selected chat from Firebase
    const messagesRef = collection(firestore, 'chats', chatId, 'messages');
    const q = query(messagesRef, orderBy('createdAt', 'asc'));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedMessages = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: data.id,
          text: data.text,
          role: data.role,
          reasoning_text: data.reasoning_text || null,
          reasoning_time: data.reasoning_time || null,
          imageUrl: data.imageUrl || null, // Use null if imageUrl doesn't exist
          pdfUrl: data.pdfUrl || null,
          pdfName: data.pdfName || null,
          audioUrl: data.audioUrl || null,
          audioName: data.audioName || null,
          videoUrl: data.videoUrl || null,
          videoName: data.videoName || null,
          fileUri: data.fileUri || null,
          mimeType: data.mimeType || null,
          citations: data.citations || null,
          createdAt: data.createdAt ? data.createdAt.toDate() : new Date(),
        };
      });

      setMessages((prevMessages) => ({
        ...prevMessages,
        [chatId]: fetchedMessages,
      }));
    });

    return unsubscribe;
  };

  const handleNewChat = () => {
    setSelectedChat(null);
    setSelectedModel(Object.values(ChatModel)[0]?.modelString || 'gpt-4o-mini');
    setMessageLimit(Object.values(ChatModel)[0]?.messageLimit);
    setMessages({ new: [] });
    setChatTitle('New Chat');
    setShowSettings(false);
    if (isMobile) {
      setIsSidebarOpen(false);
    }
  };

  const updateChatTitle = useCallback((newTitle) => {
    setChatTitle(newTitle);
  }, []);

  const handleModelChange = (selectedModelString) => {
    const selectedModelObject = Object.values(ChatModel).find(model => model.modelString === selectedModelString);

    setSelectedModel(selectedModelString);
    setSelectedModelName(selectedModelObject?.name || 'Unknown Model');
    setMessageLimit(selectedModelObject?.messageLimit || 0);
  };

  const handleMessageSent = useCallback(async (message, chatId) => {
    setMessages((prevMessages) => {
      const currentMessages = prevMessages[chatId] || [];

      // Find the index of the message with the same ID
      const existingIndex = currentMessages.findIndex(msg => msg.id === message.id);

      if (existingIndex !== -1) {
        // If the message exists, update it
        const updatedMessages = [...currentMessages];
        updatedMessages[existingIndex] = message;
        return {
          ...prevMessages,
          [chatId]: updatedMessages,
        };
      } else {
        // If it's a new message, append it
        const lastMessage = currentMessages[currentMessages.length - 1];
        if (lastMessage && lastMessage.text === message.text && lastMessage.role === message.role) {
          // It's a duplicate, don't add it
          return prevMessages;
        }
        // It's a new unique message, append it
        return {
          ...prevMessages,
          [chatId]: [...currentMessages, message],
        };

      }
    });

    // If this is a new chat, update the selectedChat state and chats list
    if (!selectedChat || selectedChat.id !== chatId) {
      const chatDoc = await getDoc(doc(firestore, 'chats', chatId));
      if (chatDoc.exists()) {
        const chatData = chatDoc.data();
        const lastMessageSent = chatData.lastMessageSent ? chatData.lastMessageSent.toDate() : new Date();
        const newChat = {
          id: chatId,
          ...chatData,
          lastMessageTimeAgo: getTimeAgo(lastMessageSent),
        };
        setSelectedChat(newChat);
        setChats((prevChats) => [newChat, ...prevChats.filter(chat => chat.id !== chatId)]);
      }
    }
  }, [selectedChat, getTimeAgo]);

  const handleUpgrade = () => {
    navigate('/subscribe');
  };

  const handleSettingsClick = () => {

    setSelectedChat(null);
    setMessages({ new: [] });
    setChatTitle('Settings');

    setShowSettings(true);
    if (isMobile) {
      setIsSidebarOpen(false);
    }

  };

  const handleBackToChat = () => {
    setShowSettings(false);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <ThemeProvider>
      <FontProvider>
        {isCheckingSubscription ? (
          <LoadingScreen />
        ) : (
          isMobile ? (
            // ─────────────────
            //    MOBILE LAYOUT
            // ─────────────────
            <div className="flex bg-[rgb(229,229,225)] dark:bg-[rgb(29,29,29)] transition-colors duration-200">
              <div className="flex flex-col h-full w-full">
                {/* Fixed MobileToolbar at the top */}
                <div className="fixed top-0 left-0 right-0 z-50">
                  <MobileToolbar
                    toggleSidebar={toggleSidebar}
                    onNewChat={handleNewChat}
                    chatTitle={chatTitle}
                    model={selectedChat ? selectedChat.model : selectedModel}
                    selectedModel={selectedModel}
                    setSelectedModel={handleModelChange}
                    hasSubscription={hasSubscription}
                    hasBasicSubscription={hasBasicSubscription}
                  />
                </div>
  
                {/* ChatWindow between toolbar and input */}
                <div className="flex-grow overflow-hidden pt-16 pb-20">
                  <div className="h-full overflow-y-auto">
                    {showSettings ? (
                      <Settings
                        onBackToChat={handleBackToChat}
                        userName={userName}
                        userEmail={userEmail}
                        hasSubscription={hasSubscription}
                        onLogout={handleLogout}
                      />
                    ) : (
                      <ChatWindow
                        selectedChat={selectedChat}
                        messages={messages[selectedChat?.id] || []}
                        selectedModel={selectedModel}
                        reasoningStream={reasoningStream}
                      />
                    )}
                  </div>
                </div>
  
                {/* Fixed MessageInput at the bottom (hidden if on Settings page) */}
                {!showSettings && (
                  <div className="fixed bottom-0 left-0 right-0 bg-[rgb(229,229,225)] dark:bg-[rgb(24,24,24)] z-40">
                    <MessageInput2
                      selectedChat={selectedChat}
                      selectedModel={selectedModel}
                      selectedModelName={selectedChat ? selectedChat.model : selectedModelName}
                      onMessageSent={handleMessageSent}
                      messages={messages[selectedChat?.id] || []}
                      updateChatTitle={updateChatTitle}
                      hasSubscription={hasSubscription}
                      hasBasicSubscription={hasBasicSubscription}
                      messageLimit={messageLimit}
                      disableInput={
                        selectedChat?.id && (messages[selectedChat.id]?.length / 2 ?? 0) > 30
                      }
                      onReasoningStreamUpdate={handleReasoningStreamUpdate}
                    />
                  </div>
                )}
  
                {/* Overlay + Sidebar when open */}
                {isSidebarOpen && (
                  <>
                    <div
                      className="fixed inset-0 bg-black bg-opacity-50 z-30"
                      onClick={toggleSidebar}
                    ></div>
                    <div className="fixed inset-y-0 left-0 w-[320px] z-40 mt-16">
                      <ChatSidebar
                        isOpen={isSidebarOpen}
                        chats={chats}
                        onChatSelect={handleChatSelect}
                        selectedChatId={selectedChat?.id}
                        hasSubscription={hasSubscription}
                        hasBasicSubscription={hasBasicSubscription}
                        onUpgrade={handleUpgrade}
                        userName={userName}
                        userEmail={userEmail}
                        onSettingsClick={handleSettingsClick}
                        isMobile={isMobile}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            // ─────────────────
            //   DESKTOP LAYOUT
            // ─────────────────
            <div className="flex h-screen bg-[rgb(229,229,225)] dark:bg-[rgb(29,29,29)] transition-colors duration-200">
              {/* 
                Instead of conditionally rendering the sidebar,
                always render it and toggle width via classes
              */}
              <div
                className={`
                  flex-shrink-0
                  transition-all
                  duration-300
                  ease-in-out
                  ${isSidebarOpen ? 'w-[320px]' : 'w-0'}
                `}
              >
                <ChatSidebar
                  isOpen={isSidebarOpen}
                  chats={chats}
                  onChatSelect={handleChatSelect}
                  selectedChatId={selectedChat?.id}
                  hasSubscription={hasSubscription}
                  hasBasicSubscription={hasBasicSubscription}
                  onUpgrade={handleUpgrade}
                  userName={userName}
                  userEmail={userEmail}
                  onSettingsClick={handleSettingsClick}
                  isMobile={isMobile}
                />
              </div>
  
              {/* Main panel: Toolbar + Chat area */}
              <div className="flex flex-col flex-grow min-w-0">
                <Toolbar
                  toggleSidebar={toggleSidebar}
                  onNewChat={handleNewChat}
                  chatTitle={chatTitle}
                  model={selectedChat ? selectedChat.model : selectedModel}
                  isSidebarOpen={isSidebarOpen}
                  selectedModel={selectedModel}
                  setSelectedModel={handleModelChange}
                  sidebarWidth={isSidebarOpen ? 300 : 0}
                  hasSubscription={hasSubscription}
                  hasBasicSubscription={hasBasicSubscription}
                  onUpgrade={handleUpgrade}
                />
  
                <div className="flex flex-grow overflow-hidden pt-16">
                  {hasSubscription ? (
                    <div className="flex flex-col w-full min-w-0">
                      {showSettings ? (
                        <Settings
                          onBackToChat={handleBackToChat}
                          userName={userName}
                          userEmail={userEmail}
                          hasSubscription={hasSubscription}
                          onLogout={handleLogout}
                        />
                      ) : (
                        <>
                          <div className="flex-grow overflow-y-auto h-[calc(100vh-200px)]">
                            <div className="w-full mx-auto h-full">
                              <ChatWindow
                                selectedChat={selectedChat}
                                messages={messages[selectedChat?.id] || []}
                                selectedModel={selectedModel}
                                reasoningStream={reasoningStream}
                              />
                            </div>
                          </div>
                          <div className="flex-shrink-0 flex justify-center">
                            <MessageInput2
                              selectedChat={selectedChat}
                              selectedModel={selectedModel}
                              selectedModelName={
                                selectedChat ? selectedChat.model : selectedModelName
                              }
                              onMessageSent={handleMessageSent}
                              messages={messages[selectedChat?.id] || []}
                              updateChatTitle={updateChatTitle}
                              hasSubscription={hasSubscription}
                              hasBasicSubscription={hasBasicSubscription}
                              messageLimit={messageLimit}
                              disableInput={
                                selectedChat?.id && (messages[selectedChat.id]?.length / 2 ?? 0) >= 30
                              }
                              onReasoningStreamUpdate={handleReasoningStreamUpdate}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <>
                      <div className="flex flex-col w-full min-w-0">
                        {showSettings ? (
                          <Settings
                            onBackToChat={handleBackToChat}
                            userName={userName}
                            userEmail={userEmail}
                            hasSubscription={hasSubscription}
                            onLogout={handleLogout}
                          />
                        ) : (
                          <>
                            <div className="flex-grow overflow-y-auto">
                              <ChatWindow
                                selectedChat={selectedChat}
                                messages={messages[selectedChat?.id] || []}
                                selectedModel={selectedModel}
                                reasoningStream={reasoningStream}
                              />
                            </div>
                            <div className="flex-shrink-0 flex justify-center">
                              <div className="w-[80%]">
                                <MessageInput2
                                  selectedChat={selectedChat}
                                  selectedModel={selectedModel}
                                  selectedModelName={
                                    selectedChat ? selectedChat.model : selectedModelName
                                  }
                                  onMessageSent={handleMessageSent}
                                  messages={messages[selectedChat?.id] || []}
                                  updateChatTitle={updateChatTitle}
                                  hasSubscription={hasSubscription}
                                  hasBasicSubscription={hasBasicSubscription}
                                  messageLimit={messageLimit}
                                  disableInput={
                                    selectedChat?.id &&
                                    (messages[selectedChat.id]?.length / 2 ?? 0) >= 30
                                  }
                                  onReasoningStreamUpdate={handleReasoningStreamUpdate}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </FontProvider>
    </ThemeProvider>
  );
  
};

export default MainChatInterface;