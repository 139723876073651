/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { auth } from './firebase';
import { loadStripe } from '@stripe/stripe-js';
import { ThemeProvider } from './ThemeContext';
import './SubscriptionScreen.css';

// Replace with your actual Stripe publishable key
const stripePromise = loadStripe('pk_live_51Q00slApEIymxqdxJNihHzZW4I4gW5AFyn8KBg1GmF8zD5wNMBglrb0yenVIULN2hUT1FlemPhUCpBvT7DUrtfh000C6iBwaSW');

const SubscriptionScreen = ({ user }) => {
    const [loading, setLoading] = useState(false);
    const [processingPriceId, setProcessingPriceId] = useState(null);
    const [error, setError] = useState(null);

    const subscriptionOptions = [
        {
            name: 'Starter',
            subtitle: 'Low commitment, high value.',
            savingsText: '60% off as opposed to individual subscriptions!',
            savingsText2: 'Would cost $20/week individually',
            priceId: 'price_1QwubYApEIymxqdxgQhLv15B',
            price: '$7.99',
            period: 'week',
            benefits: [
                'Access to premium models',
                'No limits on number of messages',
                'Ad free experience',
                'Priority access to new models/features',
                'Cancel anytime'
            ],
            isHighlighted: true
        },
        {
            name: 'Plus',
            subtitle: 'Cheaper than a ChatGPT subscription.',
            savingsText: '80% off as opposed to individual subscriptions!',
            savingsText2: 'Would cost $80/month individually',
            priceId: 'price_1QwucrApEIymxqdxxFWJqOSQ',
            price: '$14.99',
            period: 'month',
            benefits: [
                'Access to premium models',
                'No limits on number of messages',
                'Ad free experience',
                'Priority access to new models/features',
                'Cancel anytime'
            ],
            isHighlighted: true
        },
        {
            name: 'Pro',
            subtitle: 'Lock in the best price for the whole year.',
            savingsText: '85% off as opposed to individual subscriptions!',
            savingsText2: 'Would cost $960/year individually',
            priceId: 'price_1QwudoApEIymxqdxSp90tElY',
            price: '$152.99',
            period: 'year',
            benefits: [
                'Access to premium models',
                'No limits on number of messages',
                'Ad free experience',
                'Priority access to new models/features',
                'Cancel anytime'
            ],
            isHighlighted: true
        }
    ];

    const handleSubscription = async (priceId) => {
        setProcessingPriceId(priceId);
        setError(null);
        const user = auth.currentUser;

        try {
            // 1. Create a Stripe customer
            const customerResponse = await fetch('https://arrow-ai-subscription-cloud-run-123734116924.us-east1.run.app/api/create-stripe-customer', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ uid: user.uid, email: user.email }),
            });
            const customerData = await customerResponse.json();

            if (!customerData.success) {
                throw new Error('Failed to create Stripe customer');
            }

            // 2. Create a Checkout Session
            const sessionResponse = await fetch('https://arrow-ai-subscription-cloud-run-123734116924.us-east1.run.app/api/create-checkout-session', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ uid: user.uid, priceId }),
            });
            const sessionData = await sessionResponse.json();

            if (!sessionData.sessionId) {
                throw new Error('Failed to create Checkout Session');
            }

            // 3. Redirect to Checkout
            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({
                sessionId: sessionData.sessionId,
            });

            if (error) {
                throw new Error(error.message);
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setProcessingPriceId(null);
        }
    };

    const handleBillingHelp = (e) => {
        e.preventDefault();
        const subject = encodeURIComponent('Billing Support Request');
        const body = encodeURIComponent('Hello, I need help with my Arrow.AI subscription billing.\n\nMy account email: ' + auth.currentUser?.email);
        window.location.href = `mailto:anirban@arrow-ai.com?subject=${subject}&body=${body}`;
    };

    return (
        <ThemeProvider>
            <div className="min-h-screen bg-[rgb(229,229,225)] dark:bg-[rgb(29,29,29)] py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-5xl mx-auto">
                    <h1 className="text-3xl font-bold text-center text-gray-900 dark:text-white mt-8 mb-20">
                        Subscribe to Arrow.AI
                    </h1>
                    
                    {/* Plan cards */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-0">
                        {subscriptionOptions.map((plan, index) => (
                            <div 
                                key={plan.name} 
                                className={`border-y border-r first:border-l border-[rgb(209,209,205)] overflow-hidden shadow-sm dark:border-[rgb(49,49,49)] ${
                                    index === 0 
                                    ? 'rounded-l-lg md:rounded-l-lg md:rounded-r-none' 
                                    : index === subscriptionOptions.length - 1 
                                    ? 'rounded-r-lg md:rounded-r-lg md:rounded-l-none'
                                    : 'rounded-none'
                                }`}
                            >
                                <div className="p-6">
                                    <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                                        {plan.name}
                                    </h2>
                                    <div className="flex items-baseline mb-4">
                                        <span className="text-4xl font-bold text-gray-900 dark:text-white">
                                            {plan.price}
                                        </span>
                                        <span className="ml-1 text-sm text-gray-500 dark:text-gray-400">
                                            USD/{plan.period}
                                        </span>
                                    </div>
                                    <div className="text-sm text-gray-500 dark:text-gray-400 -mt-3 mb-4">
                                        {plan.savingsText}
                                    </div>
                                    
                                    <p className="text-sm text-gray-700 dark:text-gray-300 mb-2">
                                        {plan.subtitle}
                                    </p>
                                    <p className="text-sm text-gray-700 dark:text-gray-300 mb-6">
                                        {plan.savingsText2}
                                    </p>
                                    
                                    <button
                                        onClick={() => handleSubscription(plan.priceId)}
                                        disabled={processingPriceId !== null}
                                        className={`w-full py-2 px-4 rounded-md font-medium flex items-center justify-center ${
                                            plan.isCurrent 
                                            ? 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300' 
                                            : plan.isHighlighted
                                            ? 'bg-black text-white dark:bg-white dark:text-black hover:bg-gray-800 dark:hover:bg-gray-200'
                                            : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600'
                                        }`}
                                    >
                                        {processingPriceId === plan.priceId ? (
                                            <>
                                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                                Processing...
                                            </>
                                        ) : plan.isCurrent ? 'Your current plan' : `Get ${plan.name}`}
                                    </button>
                                </div>
                                
                                <div className="px-6 pb-6">
                                    <ul className="space-y-3">
                                        {plan.benefits.map((benefit, index) => (
                                            <li key={index} className="flex items-start">
                                                <svg 
                                                    className="h-5 w-5 text-green-500 mt-0.5 flex-shrink-0" 
                                                    fill="none" 
                                                    viewBox="0 0 24 24" 
                                                    stroke="currentColor"
                                                >
                                                    <path 
                                                        strokeLinecap="round" 
                                                        strokeLinejoin="round" 
                                                        strokeWidth="2" 
                                                        d="M5 13l4 4L19 7" 
                                                    />
                                                </svg>
                                                <span className="ml-2 text-sm text-gray-700 dark:text-gray-300">{benefit}</span>
                                            </li>
                                        ))}
                                    </ul>
                                    
                                    <div className="mt-6 text-xs text-gray-500 dark:text-gray-400">
                                        <a href="#" onClick={handleBillingHelp} className="hover:underline">
                                            I need help with a billing issue
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    
                    {error && (
                        <p className="mt-6 text-center text-red-600 text-sm">{error}</p>
                    )}
                </div>
            </div>
        </ThemeProvider>
    );
};

export default SubscriptionScreen;