// chatModels.js

import { doc, setDoc, getDoc } from 'firebase/firestore';
import { firestore } from './firebase';

export const AIProvider = {
    OPENAI: 'OpenAI',
    OPENAI_EXCLUSIVE: 'OpenAI Exclusive',
    OPENAI_REASONING_STREAM: 'OpenAI Reasoning Stream',
    IMAGE_GENERATION: 'Image Generation',
    ANTHROPIC: 'Anthropic',
    GOOGLE: 'Google',
    AUTOSELECTION: 'Auto Selection',
    PERPLEXITY: 'Perplexity',
    xAI: 'xAI',
    DEEPSEEK: 'DeepSeek',
    DEEPSEEK_REASONING: 'DeepSeek Reasoning',
    LLAMA: 'Meta'
};

export const InputType = {
    PNG: 'image/png',
    JPEG: 'image/jpeg',
    PDF: 'application/pdf',
    MP3: 'audio/mpeg',
    WAV: 'audio/wav',
    MP4: 'video/mp4',
    MPEG: 'video/mpeg',
    MOV: 'video/quicktime',
    MPG: 'video/mpeg',
    WEBM: 'video/webm',
};

export const ChatModel = {
    AUTO_SELECTION: {
        name: "Auto Selection",
        color: "#007AFF", // Purple color for GPT models
        provider: AIProvider.AUTOSELECTION,
        subtitle: "Picks the best model (beta)",
        symbol: "brain", // Optional if you want to use symbols
        modelString: "auto", // The model string for API calls
        maxTokens: 4048,
        isPremium: false,
        messageLimit: 150,
        online: false,
        supportedInputs: [InputType.PNG, InputType.JPEG]
    },
    PERPLEXITY: {
        name: "Perplexity",
        color: "#434343", // Purple color for GPT models
        provider: AIProvider.PERPLEXITY,
        subtitle: "Browses the web",
        symbol: "globe", // Optional if you want to use symbols
        modelString: "sonar", // The model string for API calls
        maxTokens: 4048,
        isPremium: true,
        messageLimit: 10,
        online: false,
        deep_research: true,
        supportedInputs: []
    },
    GPT4O_MINI: {
        name: "GPT-4o mini",
        color: "#8e44ad", // Purple color for GPT models
        provider: AIProvider.OPENAI,
        subtitle: "Fastest",
        symbol: "bolt.fill", // Optional if you want to use symbols
        modelString: "gpt-4o-mini", // The model string for API calls
        maxTokens: 16383,
        isPremium: false,
        messageLimit: 250,
        online: true,
        supportedInputs: [InputType.PNG, InputType.JPEG, InputType.PDF]
    },
    GPT4O: {
        name: "GPT-4o",
        color: "#8e44ad",
        provider: AIProvider.OPENAI,
        subtitle: "Flagship",
        symbol: "smallcircle.filled.circle",
        modelString: "gpt-4o", // The model string for API calls
        maxTokens: 4095,
        isPremium: false,
        messageLimit: 250,
        online: true,
        supportedInputs: [InputType.PNG, InputType.JPEG, InputType.PDF]
    },
    GPT_O3_MINI: {
        name: "o3-mini",
        color: "#1abc9c",
        provider: AIProvider.OPENAI_REASONING_STREAM,
        subtitle: "Fast Reasoning",
        symbol: "brain",
        modelString: "o3-mini", // The model string for API calls
        maxTokens: 4096,
        isPremium: false,
        messageLimit: 25,
        online: true,
        supportedInputs: []
    },
    GPT_O1: {
        name: "o1",
        color: "#1abc9c",
        provider: AIProvider.OPENAI_REASONING_STREAM,
        subtitle: "Advanced Reasoning",
        symbol: "scale.3d",
        modelString: "o1", // The model string for API calls
        maxTokens: 4096,
        isPremium: true,
        messageLimit: 15,
        online: true,
        supportedInputs: [InputType.PNG, InputType.JPEG, InputType.PDF]
    },
    GPT4_5: {
        name: "GPT-4.5",
        color: "#8e44ad",
        provider: AIProvider.OPENAI_EXCLUSIVE,
        subtitle: "Largest Model",
        symbol: "moon.stars",
        modelString: "gpt-4.5-preview", // The model string for API calls
        maxTokens: 4095,
        isPremium: false,
        requiresApiKey: true,
        messageLimit: 250,
        online: false,
        supportedInputs: [InputType.PNG, InputType.JPEG, InputType.PDF]
    },
    GPT4_TURBO: {
        name: "GPT-4 Turbo",
        color: "#1abc9c", // Teal color for Turbo models
        provider: AIProvider.OPENAI,
        subtitle: "Legacy",
        symbol: "medal.fill",
        modelString: "gpt-4-turbo", // The model string for API calls
        maxTokens: 16383,
        isPremium: true,
        messageLimit: 75,
        online: false,
        supportedInputs: [InputType.PNG, InputType.JPEG, InputType.PDF]
    },
    DALLE3: {
        name: "DALL-E 3",
        color: "#8e44ad",
        provider: AIProvider.IMAGE_GENERATION,
        subtitle: "Image generation",
        symbol: "pencil.and.outline",
        modelString: "dall-e-3", // The model string for API calls
        maxTokens: 4096,
        isPremium: false,
        messageLimit: 5
    },
    CLAUDE3_HAIKU: {
        name: "Claude 3 Haiku",
        color: "#2ecc71", // Mint color for Claude models
        provider: AIProvider.ANTHROPIC,
        subtitle: "Fast",
        symbol: "bolt.fill",
        modelString: "claude-3-haiku-20240307", // The model string for API calls
        maxTokens: 4096,
        isPremium: false,
        messageLimit: 250,
        online: true,
        supportedInputs: [InputType.PNG, InputType.JPEG]
    },
    CLAUDE3_5_HAIKU: {
        name: "Claude 3.5 Haiku",
        color: "#2ecc71", // Mint color for Claude models
        provider: AIProvider.ANTHROPIC,
        subtitle: "Fastest",
        symbol: "scale.3d",
        modelString: "claude-3-5-haiku-latest", // The model string for API calls
        maxTokens: 4096,
        isPremium: false,
        messageLimit: 100,
        online: true,
        supportedInputs: [InputType.PNG, InputType.JPEG]
    },
    CLAUDE3_OPUS: {
        name: "Claude 3 Opus",
        color: "#2ecc71",
        provider: AIProvider.ANTHROPIC,
        subtitle: "Powerful",
        symbol: "pencil.and.outline",
        modelString: "claude-3-opus-latest", // The model string for API calls
        maxTokens: 4096,
        isPremium: true,
        messageLimit: 20,
        online: true,
        supportedInputs: [InputType.PNG, InputType.JPEG]
    },
    CLAUDE3_5_SONNET: {
        name: "Claude 3.5 Sonnet",
        color: "#2ecc71",
        provider: AIProvider.ANTHROPIC,
        subtitle: "Flagship",
        symbol: "medal.fill",
        modelString: "claude-3-5-sonnet-latest", // The model string for API calls
        maxTokens: 8192,
        isPremium: true,
        messageLimit: 50,
        online: true,
        supportedInputs: [InputType.PNG, InputType.JPEG, InputType.PDF]
    },
    CLAUDE3_7_SONNET: {
        name: "Claude 3.7 Sonnet",
        color: "#2ecc71",
        provider: AIProvider.ANTHROPIC,
        subtitle: "Intelligent",
        symbol: "brain",
        modelString: "claude-3-7-sonnet-latest", // The model string for API calls
        maxTokens: 8192,
        isPremium: true,
        messageLimit: 50,
        online: false,
        thinking: true,
        supportedInputs: [InputType.PNG, InputType.JPEG, InputType.PDF]
    },
    GEMINI1_0_PRO: {
        name: "Gemini 1.0 Pro",
        color: "#e91e63",
        provider: AIProvider.GOOGLE,
        subtitle: "Simple",
        symbol: "hourglass.bottomhalf.filled",
        modelString: "gemini-1.0-pro", // The model string for API calls
        isPremium: true,
        messageLimit: 100,
        online: true,
        supportedInputs: []
    },
    GEMINI1_5_FLASH: {
        name: "Gemini 1.5 Flash",
        color: "#e91e63", // Pink color for Gemini models
        provider: AIProvider.GOOGLE,
        subtitle: "Fastest",
        symbol: "bolt.fill",
        modelString: "gemini-1.5-flash", // The model string for API calls
        isPremium: false,
        messageLimit: 250,
        online: true,
        supportedInputs: [InputType.PNG, InputType.JPEG, InputType.PDF, InputType.MP3, InputType.WAV, InputType.MP4, InputType.MPEG, InputType.MOV, InputType.MPG, InputType.WEBM]
    },
    GEMINI1_5_PRO: {
        name: "Gemini 1.5 Pro",
        color: "#e91e63",
        provider: AIProvider.GOOGLE,
        subtitle: "Powerful",
        symbol: "scale.3d",
        modelString: "gemini-1.5-pro", // The model string for API calls
        isPremium: true,
        messageLimit: 100,
        online: true,
        supportedInputs: [InputType.PNG, InputType.JPEG, InputType.PDF, InputType.MP3, InputType.WAV, InputType.MP4, InputType.MPEG, InputType.MOV, InputType.MPG, InputType.WEBM]
    },
    GEMINI2_FLASH: {
        name: "Gemini 2 Flash",
        color: "#e91e63", // Pink color for Gemini models
        provider: AIProvider.GOOGLE,
        subtitle: "Flagship",
        symbol: "medal.fill",
        modelString: "gemini-2.0-flash-001", // The model string for API calls
        isPremium: false,
        messageLimit: 20,
        online: true,
        supportedInputs: [InputType.PNG, InputType.JPEG, InputType.PDF, InputType.MP3, InputType.WAV, InputType.MP4, InputType.MPEG, InputType.MOV, InputType.MPG, InputType.WEBM]
    },
    GEMINI2_PRO: {
        name: "Gemini 2 Pro",
        color: "#e91e63",
        provider: AIProvider.GOOGLE,
        subtitle: "Experimental",
        symbol: "brain",
        modelString: "gemini-2.0-pro-exp-02-05",
        isPremium: true,
        messageLimit: 20,
        online: false,
        supportedInputs: [InputType.PNG, InputType.JPEG, InputType.PDF, InputType.MP3, InputType.WAV, InputType.MP4, InputType.MPEG, InputType.MOV, InputType.MPG, InputType.WEBM]
    },
    GROK_2: {
        name: "Grok 2",
        color: "#FF8000",
        provider: AIProvider.xAI,
        subtitle: "Powered by X",
        symbol: "pencil.and.outline",
        modelString: "grok-2-vision-1212",
        isPremium: true,
        messageLimit: 100,
        online: true,
        supportedInputs: [InputType.PNG, InputType.JPEG]
    },
    DEEPSEEK_V3: {
        name: "DeepSeek-V3",
        color: "#a232a8",
        provider: AIProvider.DEEPSEEK,
        subtitle: "Open Source",
        symbol: "brain",
        modelString: "deepseek-chat",
        isPremium: false,
        messageLimit: 100,
        online: true,
        supportedInputs: []
    },
    DEEPSEEK_REASONER: {
        name: "DeepSeek-R1",
        color: "#a232a8",
        provider: AIProvider.DEEPSEEK_REASONING,
        subtitle: "Reasoning",
        symbol: "scale.3d",
        modelString: "deepseek-reasoner",
        isPremium: false,
        messageLimit: 100,
        online: true,
        supportedInputs: []
    },
    LLAMA_3_1: {
        name: "Llama 3.1",
        color: "#FF8000",
        provider: AIProvider.LLAMA,
        subtitle: "Fastest",
        symbol: "bolt.fill",
        modelString: "llama-3.1-8b-instant",
        isPremium: false,
        messageLimit: 100,
        online: true,
        supportedInputs: []
    },
    LLAMA_3_3: {
        name: "Llama 3.3",
        color: "#FF8000",
        provider: AIProvider.LLAMA,
        subtitle: "Intelligent",
        symbol: "brain",
        modelString: "llama-3.3-70b-versatile",
        isPremium: true,
        messageLimit: 100,
        online: true,
        supportedInputs: []
    },
};

export const getChatModelByName = (name) => {
    return Object.values(ChatModel).find(model => model.name === name);
};
export const getChatModelByModelString = (modelString) => {
    return Object.values(ChatModel).find(model => model.modelString === modelString);
};

export const getEndpoint = (model, hasSubscription) => {
    if (isAutoSelection(model)) {
        return hasSubscription
            ? 'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/intelligent-model-selection-paid'
            : 'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/intelligent-model-selection-free';
    } else if (isPerplexity(model)) {
        // return 'http://192.168.6.242:8080/api/perplexity_no_stream';
        return 'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/perplexity_no_stream';
    } else if (isOpenAI(model)) {
        // return 'http://192.168.6.242:8080/api/openai';
        return 'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/openai';
    } else if (isOpenAIExclusive(model)) {
        return 'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/openai-exclusive';
    } else if (isOpenAIReasoningStream(model)) {
        // return 'http://192.168.6.242:8080/api/openai-reasoning2';
        return 'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/openai-reasoning2';
    } else if (isAnthropic(model)) {
        return 'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/anthropic_web';
    } else if (isGoogle(model)) {
        return 'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/gemini';
    } else if (isXAI(model)) {
        return 'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/xAI';
    } else if (isDeepSeek(model)) {
        return 'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/deepseek';
    } else if (isDeepSeekReasoning(model)) {
        return 'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/deepseek_reasoning';
    } else if (isLlama(model)) {
        return 'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/groq';
    } else if (isImageGeneration(model)) {
        return 'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/openai-image-gen3';
    }
};

export const isAutoSelection = (model) => {
    return model.provider === AIProvider.AUTOSELECTION;
};
export const isPerplexity = (model) => {
    return model.provider === AIProvider.PERPLEXITY;
};
export const isOpenAI = (model) => {
    return model.provider === AIProvider.OPENAI;
};
export const isOpenAIExclusive = (model) => {
    return model.provider === AIProvider.OPENAI_EXCLUSIVE;
};
export const isOpenAIReasoningStream = (model) => {
    return model.provider === AIProvider.OPENAI_REASONING_STREAM;
};
export const isAnthropic = (model) => {
    return model.provider === AIProvider.ANTHROPIC;
};
export const isGoogle = (model) => {
    return model.provider === AIProvider.GOOGLE;
};
export const isXAI = (model) => {
    return model.provider === AIProvider.xAI;
};
export const isDeepSeek = (model) => {
    return model.provider === AIProvider.DEEPSEEK;
};
export const isDeepSeekReasoning = (model) => {
    return model.provider === AIProvider.DEEPSEEK_REASONING;
};
export const isLlama = (model) => {
    return model.provider === AIProvider.LLAMA;
};
export const isImageGeneration = (model) => {  
    return model.provider === AIProvider.IMAGE_GENERATION;
};

export const getMaxTokens = (modelString) => {
    const model = getChatModelByModelString(modelString);
    return model ? model.maxTokens : 4000; // Default to 4000 if model not found
};

export const getModelSupportedInputs = (model) => {
    return model?.supportedInputs || [];
};

export const isOpenAIKeyRequired = (model) => {
    return model.requiresApiKey === true;
};

export const saveUserApiKey = async (userId, apiKey) => {
    if (!userId) return false;
    try {
        const userRef = doc(firestore, 'users_api_keys', userId);
        await setDoc(userRef, { openaiKey: apiKey }, { merge: true });
        return true;
    } catch (error) {
        console.error('Error saving API key:', error);
        return false;
    }
};

export const getUserApiKey = async (userId) => {
    if (!userId) return null;
    try {
        const userRef = doc(firestore, 'users_api_keys', userId);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists() && userDoc.data().openaiKey) {
            return userDoc.data().openaiKey;
        }
        return null;
    } catch (error) {
        console.error('Error getting API key:', error);
        return null;
    }
};