import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './LandingPage.css';
import { ChatModel } from './chatModels';

// Import icons/logos as needed
import { 
  ChevronDown, 
  Check, 
  X, 
  ArrowRight,
  Zap,
  DollarSign,
  Repeat,
  Infinity,
  Shield,
  BarChart2,
  Clock,
  Menu
} from 'lucide-react';

const LandingPage = () => {  
  // Ref for pricing section intersection observer
  const pricingRef = useRef(null);
  const [isPricingVisible, setIsPricingVisible] = useState(false);
  
  // FAQ state
  const [openFaq, setOpenFaq] = useState(null);
  
  // Add this new state for mobile menu
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  
  // Add this new state for navbar scroll effect
  const [scrolled, setScrolled] = useState(false);
  
  // Add this new state for theme detection
  const [isDarkMode, setIsDarkMode] = useState(false);
  
  // Pricing comparison data - Move this up before it's used
  const pricingData = [
    { service: "ChatGPT (OpenAI)", price: 20, note: "Limited models" },
    { service: "Claude (Anthropic)", price: 20, note: "Limited models" },
    { service: "Google (Gemini)", price: 20, note: "Different app" },
    { service: "Perplexity", price: 20, note: "Usage limits" },
    { service: "Others", price: 10, note: "Basic features" },
  ];
  
  // Add this new state for tracking selected subscriptions
  const [selectedServices, setSelectedServices] = useState(pricingData.map(() => true));
  
  // Add this useEffect for scroll detection
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };
    
    document.addEventListener('scroll', handleScroll);
    
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);
  
  // Set up intersection observer for pricing section
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsPricingVisible(true);
        }
      },
      { threshold: 0.3 }
    );
    
    if (pricingRef.current) {
      observer.observe(pricingRef.current);
    }
    
    return () => {
      if (pricingRef.current) {
        observer.unobserve(pricingRef.current);
      }
    };
  }, []);
  
  // Add useEffect for theme detection
  useEffect(() => {
    // Check initial preference
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setIsDarkMode(darkModeMediaQuery.matches);
    
    // Listen for changes in color scheme preference
    const handleThemeChange = (e) => {
      setIsDarkMode(e.matches);
    };
    
    darkModeMediaQuery.addEventListener('change', handleThemeChange);
    
    return () => {
      darkModeMediaQuery.removeEventListener('change', handleThemeChange);
    };
  }, []);
  
  // FAQ data
  const faqs = [
    {
      question: "How does Arrow.AI's subscription work?",
      answer: "Arrow.AI offers a simple $14.99/month subscription that gives you unlimited access to all AI models on our platform. No hidden fees, no usage limits, just straightforward access to the best AI models available."
    },
    {
      question: "Can I switch between different AI models?",
      answer: "Absolutely! You can switch between models with a single click. Compare responses, find the best model for your specific needs, and seamlessly continue your conversation with any model you choose."
    },
    {
      question: "How often do you add new models?",
      answer: "We add new models as soon as they're released. We try our best to ensure Arrow.AI users get day-one access to the latest and greatest models without having to manage multiple subscriptions."
    },
    {
      question: "Is my data private and secure?",
      answer: "Privacy is our priority. We only store your conversations as long as you don't delete them, we don't train on your data, and we don't share any data with third parties."
    },
    {
      question: "Is there a free version available?",
      answer: "Yes! We offer a free version so you can experience the full power of Arrow.AI before committing to a subscription. No credit card required to start your free version."
    }
  ];
  
  // Testimonial data - update to remove avatar URLs and add initials
  const testimonials = [
    {
      name: "Sarah J.",
      role: "Content Creator",
      text: "Arrow.AI has completely transformed my workflow. I used to juggle between Claude and GPT subscriptions, but now I have everything in one place for less than half the cost. The ability to instantly compare outputs from different models is invaluable.",
      initials: "SJ"
    },
    {
      name: "Michael T.",
      role: "Software Developer",
      text: "As a developer, I need different AI strengths for different tasks. Arrow.AI lets me use Anthropic's models for reasoning through complex problems and OpenAI for code generation - all without switching apps or managing multiple subscriptions.",
      initials: "MT"
    },
    {
      name: "Elena R.",
      role: "Small Business Owner",
      text: "The cost savings alone made me switch to Arrow.AI, but what keeps me is how they instantly add new models. I was using Claude 3 Opus the day it launched without having to sign up for anything new or pay extra. Brilliant service!",
      initials: "ER"
    }
  ];
  
  // Calculate total of selected individual subscriptions
  const totalIndividualPrice = pricingData.reduce((sum, item, index) => 
    sum + (selectedServices[index] ? item.price : 0), 0);

  return (
    <div className="landing-page">
      {/* Navbar - Add this section */}
      <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
        <div className="navbar-container">
          <div className="navbar-logo">
            <a href="/">
              <span className="logo-arrow">Arrow</span>
              <span className="logo-ai">.AI</span>
            </a>
          </div>
          
          <div className={`navbar-menu ${mobileMenuOpen ? 'active' : ''}`}>
            <ul className="navbar-links">
              <li><a href="#demo">Demo</a></li>
              <li><a href="#features">Features</a></li>
              <li><a href="#pricing">Pricing</a></li>
              <li><a href="#models">Models</a></li>
              <li><a href="#faq">FAQ</a></li>
            </ul>
            
            <div className="navbar-buttons">
              <a href="/login" className="login-button">Log in</a>
              <a href="/signup" className="signup-button">Sign up</a>
            </div>
          </div>
          
          <button 
            className="mobile-menu-toggle" 
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            aria-label="Toggle menu"
          >
            {mobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </nav>
      
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-container">
          <div className="hero-content-wrapper">
            <motion.div 
              className="hero-content"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <h1>
                <span className="gradient-text">All AI Models.</span>
                <br />Only One Subscription.
              </h1>
              <p className="hero-subtitle">
                Access Claude, GPT-4, Gemini, and more for less than the price of a single service.
                <br />Switch between models instantly. Compare results. Unlimited usage.
              </p>
              
              <div className="app-store-buttons">
                  <a 
                    href="https://apps.apple.com/us/app/arrowai-ai-chatbot-assistant/id6618111835" 
                    className="app-store-button"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img 
                      src="/app-store.svg" 
                      alt="Download on the App Store" 
                    />
                  </a>
                </div>
              <motion.div 
                className="cta-button-container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.5 }}
              >
                <a href="/signup" className="primary-button">
                  Try For Free <ArrowRight size={18} />
                </a>
                <p className="no-credit-card">No credit card required</p>
              </motion.div>
            </motion.div>
            
            <motion.div 
              className="hero-screenshot"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, duration: 0.8 }}
              whileHover={{ 
                y: -15, 
                rotate: -2,
                transition: { duration: 0.3 }
              }}
            >
              <motion.div 
                className="screenshot-content"
                animate={{ 
                  y: [0, -10, 0],
                  rotate: [-5, -6, -5]
                }}
                transition={{ 
                  repeat: Infinity, 
                  duration: 6,
                  ease: "easeInOut"
                }}
              >
                <img 
                  src={isDarkMode ? "/dark_mode_app_screenshot.jpeg" : "/light_mode_app_screenshot.jpeg"} 
                  alt="Arrow.AI interface" 
                />
              </motion.div>
              <div className="screenshot-glow"></div>
            </motion.div>
          </div>
        </div>
        
        <div className="hero-background">
          <div className="gradient-blob blob-1"></div>
          <div className="gradient-blob blob-2"></div>
          <div className="gradient-blob blob-3"></div>
        </div>
      </section>
      
      {/* Demo Section */}
      <section id="demo" className="demo-section">
        <div className="section-container">
          <motion.div 
            className="section-header"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <h2>See Arrow.AI in Action</h2>
            <p>Watch how easily you can switch between models and compare results</p>
          </motion.div>
          
          <motion.div 
            className="video-container"
            initial={{ opacity: 0, scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            <div className="video-placeholder">
              <div className="play-button">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 5V19L19 12L8 5Z" fill="currentColor" />
                </svg>
              </div>
              <p>Watch Demo</p>
            </div>
          </motion.div>
        </div>
      </section>
      
      {/* Why Choose Section */}
      <section id="features" className="why-choose-section">
        <div className="section-container">
          <motion.div 
            className="section-header"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <h2>Why Choose Arrow.AI?</h2>
            <p>The smartest way to access all AI models</p>
          </motion.div>
          
          <div className="features-grid">
            {[
              { 
                icon: <DollarSign />, 
                title: "Cost Effective", 
                description: "Save up to 80% compared to individual subscriptions" 
              },
              { 
                icon: <Repeat />, 
                title: "Switch Instantly", 
                description: "Change models with a single click, mid-conversation" 
              },
              { 
                icon: <Infinity />, 
                title: "Unlimited Usage", 
                description: "No tokens, no limits, just unlimited access to all models" 
              },
              { 
                icon: <Shield />, 
                title: "Privacy First", 
                description: "We don't store your data or train on your conversations" 
              },
              { 
                icon: <BarChart2 />, 
                title: "Compare Results", 
                description: "See how different models respond to the same prompt" 
              },
              { 
                icon: <Clock />, 
                title: "Instant Access", 
                description: "New models added the day they're released" 
              }
            ].map((feature, index) => (
              <motion.div 
                className="feature-card"
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1, duration: 0.5 }}
              >
                <div className="feature-icon">
                  {feature.icon}
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
      
      {/* Pricing Section */}
      <section id="pricing" className="pricing-section" ref={pricingRef}>
        <div className="section-container">
          <motion.div 
            className="section-header"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <h2>Transparent Pricing</h2>
            <p>See how much you'll save with Arrow.AI</p>
          </motion.div>
          
          <div className="pricing-comparison">
            <motion.div 
              className="pricing-table equal-height-card"
              initial={{ opacity: 0, x: -20 }}
              animate={isPricingVisible ? { opacity: 1, x: 0 } : {}}
              transition={{ duration: 0.8 }}
            >
              <div className="pricing-header">
                <h3>Individual Subscriptions</h3>
                <div className="pricing-amount">
                  <span className="price">${totalIndividualPrice}</span>
                  <span className="period">/month</span>
                </div>
              </div>
              <div className="pricing-body">
                <p className="pricing-notes">Limited models, different apps, usage limits, and basic features</p>
                {pricingData.map((item, index) => (
                  <motion.div 
                    className="pricing-row"
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={isPricingVisible ? { opacity: 1 } : {}}
                    transition={{ delay: 0.1 + index * 0.1, duration: 0.5 }}
                    style={index === pricingData.length - 1 ? { borderBottom: 'none' } : {}}
                  >
                    <div className="pricing-service-info">
                      <span>{item.service}</span>
                    </div>
                    <div className="pricing-price-toggle">
                      <span>${item.price}/month</span>
                      <label className="switch">
                        <input 
                          type="checkbox" 
                          checked={selectedServices[index]}
                          onChange={() => {
                            const newSelected = [...selectedServices];
                            newSelected[index] = !newSelected[index];
                            setSelectedServices(newSelected);
                          }}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </motion.div>
                ))}
              </div>
            </motion.div>
            
            <div className="pricing-vs">VS</div>
            
            <motion.div 
              className="pricing-card equal-height-card"
              initial={{ opacity: 0, x: 20 }}
              animate={isPricingVisible ? { opacity: 1, x: 0 } : {}}
              transition={{ duration: 0.8 }}
            >
              <div className="pricing-card-header">
                <h3>Arrow.AI</h3>
                <div className="pricing-amount">
                  <span className="price">$14.99</span>
                  <span className="period">/month</span>
                </div>
              </div>
              <div className="pricing-card-body">
                <ul className="pricing-features">
                  <li><Check size={18} /> Access to all AI models</li>
                  <li><Check size={18} /> Unlimited usage</li>
                  <li><Check size={18} /> Switch models instantly</li>
                  <li><Check size={18} /> Compare model outputs</li>
                  <li><Check size={18} /> New models added immediately</li>
                </ul>
                <a href="/signup" className="primary-button">
                  Try Free Version
                </a>
              </div>
            </motion.div>
          </div>
          
          <motion.div 
            className="savings-callout"
            initial={{ opacity: 0, y: 20 }}
            animate={isPricingVisible ? { opacity: 1, y: 0 } : {}}
            transition={{ delay: 0.5, duration: 0.8 }}
          >
            <Zap size={24} />
            <h3>Save ${(totalIndividualPrice - 14.99).toFixed(2)} every month with Arrow.AI</h3>
          </motion.div>
        </div>
      </section>
      
      {/* All Models Section */}
      <section id="models" className="models-section">
        <div className="section-container">
          <motion.div 
            className="section-header"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <h2>All Models Included</h2>
            <p>One subscription, unlimited access to the world's best AI models</p>
          </motion.div>
          
          <motion.div 
            className="models-grid"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            {Object.values(ChatModel)
              .filter(model => model.name !== "Auto Selection" && model.name !== "DALL-E 3")
              .map((model, index) => (
                <motion.div 
                  className="model-item"
                  key={index}
                  initial={{ opacity: 0, y: 10 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.05, duration: 0.5 }}
                >
                  {model.name}
                </motion.div>
              ))}
          </motion.div>
          
          <motion.div 
            className="models-note"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.5, duration: 0.8 }}
          >
            <p>+ New models added as soon as they're released</p>
          </motion.div>
        </div>
      </section>
      
      {/* Testimonials Section */}
      <section id="testimonials" className="testimonials-section">
        <div className="section-container">
          <motion.div 
            className="section-header"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <h2>What Users Say</h2>
            <p>Join thousands of satisfied Arrow.AI users</p>
          </motion.div>
          
          <div className="testimonials-grid">
            {testimonials.map((testimonial, index) => (
              <motion.div 
                className="testimonial-card"
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2, duration: 0.8 }}
              >
                <div className="testimonial-content">
                  <p>"{testimonial.text}"</p>
                </div>
                <div className="testimonial-author">
                  <div className="avatar-circle">
                    {testimonial.initials}
                  </div>
                  <div>
                    <h4>{testimonial.name}</h4>
                    <p>{testimonial.role}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
      
      {/* FAQ Section */}
      <section id="faq" className="faq-section">
        <div className="section-container">
          <motion.div 
            className="section-header"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <h2>Frequently Asked Questions</h2>
            <p>Everything you need to know about Arrow.AI</p>
          </motion.div>
          
          <div className="faq-container">
            {faqs.map((faq, index) => (
              <motion.div 
                className={`faq-item ${openFaq === index ? 'open' : ''}`}
                key={index}
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1, duration: 0.5 }}
              >
                <div 
                  className="faq-question"
                  onClick={() => setOpenFaq(openFaq === index ? null : index)}
                >
                  <h3>{faq.question}</h3>
                  <div className="faq-icon">
                    {openFaq === index ? <X size={18} /> : <ChevronDown size={18} />}
                  </div>
                </div>
                <AnimatePresence>
                  {openFaq === index && (
                    <motion.div 
                      className="faq-answer"
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <p>{faq.answer}</p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
      
      {/* Final CTA Section */}
      <section id="signup" className="final-cta-section">
        <div className="section-container">
          <motion.div 
            className="cta-content"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <h2>Ready to experience all AI models in one place?</h2>
            <p>Start with our free version today. No credit card required.</p>
            
            <div className="cta-buttons">
              <a href="/signup" className="primary-button">
                Try For Free <ArrowRight size={18} />
              </a>
            </div>
          </motion.div>
        </div>
        
        <div className="cta-background">
          <div className="gradient-blob blob-4"></div>
          <div className="gradient-blob blob-5"></div>
        </div>
      </section>
      
      {/* Footer */}
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-top">
            <div className="footer-logo">
              <h3>Arrow.AI</h3>
              <p>All AI models. One subscription.</p>
            </div>
            
            <div className="footer-links">
              <div className="footer-links-column">
                <h4>Product</h4>
                <ul>
                  <li><a href="#features">Features</a></li>
                  <li><a href="#pricing">Pricing</a></li>
                  <li><a href="#models">Models</a></li>
                  <li><a href="#faq">FAQ</a></li>
                </ul>
              </div>
              
              <div className="footer-links-column">
                <h4>Company</h4>
                <ul>
                  <li><a href="#about">About Us</a></li>
                  <li><a href="#blog">Blog</a></li>
                  <li><a href="#careers">Careers</a></li>
                  <li><a href="#contact">Contact</a></li>
                </ul>
              </div>
              
              {/* <div className="footer-links-column">
                <h4>Legal</h4>
                <ul>
                  <li><a href="#terms">Terms of Service</a></li>
                  <li><a href="#privacy">Privacy Policy</a></li>
                </ul>
              </div> */}
            </div>
          </div>
          
          <div className="footer-bottom">
            <p>&copy; 2025 Arrow.AI. All rights reserved.</p>
            <div className="social-links">
              <a href="https://x.com/tryarrow_ai" aria-label="Twitter" target="_blank" rel="noopener noreferrer">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23 3.01s-2.018 1.192-3.14 1.53a4.48 4.48 0 00-7.86 3v1a10.66 10.66 0 01-9-4.53s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5 0-.278-.028-.556-.08-.83C21.94 5.674 23 3.01 23 3.01z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </a>
              <a href="https://instagram.com/tryarrow_ai" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="2" y="2" width="20" height="20" rx="5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <circle cx="12" cy="12" r="4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <circle cx="17.5" cy="6.5" r="1.5" fill="currentColor"/>
                </svg>
              </a>
              <a href="https://tiktok.com/@arrow.ai" aria-label="TikTok" target="_blank" rel="noopener noreferrer">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 2h-3v12.5a3.5 3.5 0 1 1-3.5-3.5V8a7 7 0 1 0 7 7V2Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage; 