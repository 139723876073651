import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const PremiumFeatureAlert = ({
  primaryText,
  subText,
  onClose,
  duration = 0,
  isDarkMode
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (duration > 0) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        onClose();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [duration, onClose]);

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  const handleSubscribe = () => {
    navigate('/subscribe');
    onClose();
  };

  if (!isVisible) return null;

  return createPortal(
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" onClick={handleClose}></div>
      <div className={`relative bg-white dark:bg-[rgb(22,22,22)] rounded-lg p-6 shadow-xl max-w-md w-full mx-4 transform transition-all ease-in-out duration-300 ${isVisible ? 'scale-100 opacity-100' : 'scale-95 opacity-0'}`}>
        <button
          onClick={handleClose}
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-500 focus:outline-none"
        >
          <X size={20} />
        </button>
        <div className="mt-3 text-center">
          <h3 className={`text-lg leading-6 font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {primaryText}
          </h3>
          <div className="mt-2">
            <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>
              {subText}
            </p>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 flex justify-center">
          <button
            type="button"
            onClick={handleSubscribe}
            className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
          >
            Subscribe
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default PremiumFeatureAlert;