import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { AIProvider, ChatModel } from './chatModels';
import PremiumFeatureAlert from './components/ui/alert';
import { useTheme } from './ThemeContext';
import { auth } from './firebase';
import { isOpenAIKeyRequired, saveUserApiKey, getUserApiKey } from './chatModels';
import ApiKeyDialog from './components/ui/ApiKeyDialog';

const CustomDropdown = ({ options, value, onChange, hasSubscription, onUpgrade }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [lastPremiumAttempt, setLastPremiumAttempt] = useState(null);
  const [showApiKeyDialog, setShowApiKeyDialog] = useState(false);
  const [pendingModelSelection, setPendingModelSelection] = useState(null);
  const dropdownRef = useRef(null);
  const { theme } = useTheme();

  const isDarkMode =
    theme === 'dark' ||
    (theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches);

  const modelOptions = Object.values(ChatModel).map((model) => ({
    display: model.name,
    value: model.modelString,
    provider: model.provider,
    isPremium: model.isPremium,
    subtitle: model.subtitle,
    color: model.color,
    symbol: model.symbol,
  }));

  const getSymbolImage = (symbolName) => {
    const mode = isDarkMode ? 'dark' : 'light';
    return `/symbols/${symbolName}.${mode}.png`;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setOpenSubmenu(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = async (option, event) => {
    event.stopPropagation();

    if (option.subOptions) {
      return;
    }

    if (option.isPremium && !hasSubscription) {
      setShowAlert(true);
      setLastPremiumAttempt(option.value);
      return;
    }
    
    const modelDetails = Object.values(ChatModel).find(model => model.modelString === option.value);
    if (modelDetails && isOpenAIKeyRequired(modelDetails)) {
      const userApiKey = await getUserApiKey(auth.currentUser?.uid);
      if (!userApiKey) {
        setPendingModelSelection(option.value);
        setShowApiKeyDialog(true);
        return;
      }
    }

    onChange(option.value);
    setIsOpen(false);
    setOpenSubmenu(null);
  };

  const handleAlertClose = () => {
    setShowAlert(false);
    setLastPremiumAttempt(null);
  };

  const handleApiKeySubmit = async (apiKey) => {
    const success = await saveUserApiKey(auth.currentUser?.uid, apiKey);
    if (success && pendingModelSelection) {
      onChange(pendingModelSelection);
      setPendingModelSelection(null);
    }
    setShowApiKeyDialog(false);
  };

  const handleApiKeyDialogClose = () => {
    setShowApiKeyDialog(false);
    setPendingModelSelection(null);
  };

  const getSelectedModel = () => {
    const findModel = (opts) => opts.find((opt) => opt.value === value);

    let selectedOption = findModel(modelOptions);
    if (selectedOption) return selectedOption;

    for (const option of groupedOptions) {
      if (option.subOptions) {
        selectedOption = findModel(option.subOptions);
        if (selectedOption) return selectedOption;
      }
    }

    // Default to first if nothing is found
    return modelOptions[0];
  };

  const selectedModel = getSelectedModel();

  const getIcon = (option) => {
    if (option && option.symbol) {
      return (
        <img
          src={getSymbolImage(option.symbol)}
          alt={option.display}
          className="w-5 h-5 object-contain"
        />
      );
    }
    return null;
  };

  /**
   * Hover-based open/close:
   * - `onMouseEnter` sets `openSubmenu`
   * - `onMouseLeave` resets `openSubmenu` to `null`
   */
  const renderOptions = (optionsToRender) => {
    return optionsToRender.map((option, index) => (
      <li
        key={index}
        className="relative"
        onMouseEnter={() => option.subOptions && setOpenSubmenu(option.value)}
        onMouseLeave={() => option.subOptions && setOpenSubmenu(null)}
      >
        <div
          onClick={(e) => handleOptionClick(option, e)}
          className={`px-4 py-2 cursor-pointer flex items-center justify-between ${
            option.value === value
              ? 'dark:bg-[rgb(30,30,30)] bg-[rgb(199,199,195)]'
              : isDarkMode
              ? 'text-white'
              : 'text-gray-800'
          } ${
            isDarkMode
              ? 'hover:bg-[rgb(40,40,40)]'
              : 'hover:bg-[rgb(189,189,185)]'
          }`}
        >
          <div className="flex items-center">
            <div className="flex-shrink-0 w-6 h-6 mr-3 flex items-center justify-center">
              {option.symbol && (
                <img
                  src={getSymbolImage(option.symbol)}
                  alt={option.display}
                  className="w-5 h-5 object-contain"
                />
              )}
            </div>
            <div className="flex flex-col">
              <span className="font-semibold">{option.display}</span>
              {option.subtitle && (
                <span className="text-xs text-gray-500 dark:text-gray-400">
                  {option.subtitle}
                </span>
              )}
            </div>
          </div>
          <div className="flex items-center">
            {option.isPremium && !hasSubscription && (
              <img 
                src={isDarkMode ? "/symbols/lock-white.png" : "/symbols/lock-black.png"} 
                alt="Premium" 
                className="w-3 h-4 mr-2" 
              />
            )}
            {option.subOptions && (
              <ChevronRight
                size={16}
                color={isDarkMode ? 'white' : 'currentColor'}
                className={`transition-transform duration-200 ${
                  openSubmenu === option.value ? 'transform rotate-90' : ''
                }`}
              />
            )}
          </div>
        </div>
        {option.subOptions && openSubmenu === option.value && (
          <ul
            className={`absolute top-0 left-full mt-0 ml-0.5 border rounded-md shadow-lg ${
              isDarkMode
                ? 'bg-[rgb(36,36,36)] border-[rgb(36,36,36)] text-white'
                : 'bg-[rgb(209,209,205)] border-[rgb(219,219,215)] text-gray-800'
            }`}
            style={{ minWidth: '250px' }}
          >
            {renderOptions(option.subOptions)}
          </ul>
        )}
      </li>
    ));
  };

  const groupedOptions = [
    modelOptions.find((o) => o.value === ChatModel.AUTO_SELECTION.modelString),
    modelOptions.find((o) => o.value === ChatModel.PERPLEXITY.modelString),
    {
      display: 'OpenAI',
      value: 'OPENAI',
      subtitle: 'ChatGPT models',
      subOptions: modelOptions.filter(
        (o) =>
          o.provider === AIProvider.OPENAI ||
          o.provider === AIProvider.OPENAI_EXCLUSIVE || 
          o.provider === AIProvider.OPENAI_REASONING_STREAM ||
          o.provider === AIProvider.IMAGE_GENERATION
      ),
    },
    {
      display: 'Anthropic',
      value: 'ANTHROPIC',
      subtitle: 'Claude models',
      subOptions: modelOptions.filter((o) => o.provider === AIProvider.ANTHROPIC),
    },
    {
      display: 'Google',
      value: 'GOOGLE',
      subtitle: 'Gemini models',
      subOptions: modelOptions.filter(
        (o) =>
          o.provider === AIProvider.GOOGLE ||
          o.provider === AIProvider.GOOGLE_REASONING 
      ),
    },
    {
      display: 'xAI',
      value: 'xAI',
      subtitle: 'xAI models',
      subOptions: modelOptions.filter((o) => o.provider === AIProvider.xAI),
    },
    {
      display: 'DeepSeek',
      value: 'DEEPSEEK',
      subtitle: 'DeepSeek models',
      subOptions: modelOptions.filter(
        (o) =>
          o.provider === AIProvider.DEEPSEEK ||
          o.provider === AIProvider.DEEPSEEK_REASONING
      ),
    },
    {
      display: 'Meta',
      value: 'LLAMA',
      subtitle: 'LLAMA models',
      subOptions: modelOptions.filter((o) => o.provider === AIProvider.LLAMA),
    },
  ].filter(Boolean);

  return (
    <div className="relative inline-block w-full" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className={`w-full px-4 py-2 text-left rounded-md flex items-center justify-between transition-colors duration-150 ${
          isDarkMode
            ? 'bg-[rgb(36,36,36)] text-white hover:bg-[rgb(29,29,29)]'
            : 'bg-[rgb(219,219,215)] text-gray-800 hover:bg-[rgb(209,209,205)]'
        }`}
      >
        <div className="flex items-center flex-grow">
          <div className="flex-shrink-0 w-6 h-6 mr-3 flex items-center justify-center">
            {getIcon(selectedModel)}
          </div>
          <div className="flex flex-col">
            <span className="text-lg font-semibold">{selectedModel.display}</span>
          </div>
        </div>
        <ChevronDown
          className={`transition-transform duration-200 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
          color={isDarkMode ? 'white' : 'black'}
          size={24}
        />
      </button>
      <div 
        className={`absolute left-1/2 transform -translate-x-1/2 mt-1 z-10 transition-all duration-300 ease-out origin-top ${
          isOpen ? 'opacity-100 scale-y-100 visible' : 'opacity-0 scale-y-0 invisible pointer-events-none'
        }`}
      >
        <ul
          className={`border rounded-md shadow-lg ${
            isDarkMode
              ? 'bg-[rgb(36,36,36)] border-[rgb(36,36,36)] text-white'
              : 'bg-[rgb(209,209,205)] border-[rgb(219,219,215)] text-gray-800'
          }`}
          style={{ width: '250px' }}
        >
          {renderOptions(groupedOptions)}
        </ul>
      </div>
      {showAlert && (
        <PremiumFeatureAlert
          primaryText="Premium Feature"
          subText="This model is only available to premium subscribers. Upgrade now to access advanced AI capabilities."
          onSubscribe={onUpgrade}
          onClose={handleAlertClose}
          isDarkMode={isDarkMode}
        />
      )}
      
      {showApiKeyDialog && (
        <ApiKeyDialog
          onSubmit={handleApiKeySubmit}
          onClose={handleApiKeyDialogClose}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
};

export default CustomDropdown;
