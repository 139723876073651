import React, { useState } from 'react';

const ApiKeyDialog = ({ onSubmit, onClose, isDarkMode }) => {
  const [apiKey, setApiKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!apiKey.trim()) return;
    
    setIsLoading(true);
    await onSubmit(apiKey.trim());
    setIsLoading(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className={`w-full max-w-md p-6 rounded-md shadow-lg transition-colors duration-200 ${
        isDarkMode 
          ? 'bg-[rgb(36,36,36)] border border-[rgb(40,40,40)] text-white' 
          : 'bg-[rgb(219,219,215)] border border-[rgb(219,219,215)] text-gray-800'
      }`}>
        <div className="flex flex-col">
          <h2 className="text-xl font-semibold mb-3">API Key Required</h2>
          
          <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            This model requires your own OpenAI API key. Your key will be securely stored for future use and used exclusively for this model.
          </p>
          
          <form onSubmit={handleSubmit}>
            <div className="mb-5">
              <label className={`block text-sm font-medium mb-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                OpenAI API Key
              </label>
              <input
                type="password"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                className={`w-full px-3 py-2 border rounded-md transition-colors duration-200 ${
                  isDarkMode 
                    ? 'bg-[rgb(29,29,29)] border-[rgb(50,50,50)] text-white focus:border-gray-500' 
                    : 'bg-[rgb(229,229,225)] border-[rgb(199,199,195)] text-gray-800 focus:border-gray-400'
                }`}
                placeholder="sk-..."
                required
              />
              <p className={`mt-1 text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                Your API key starts with 'sk-' and can be found in your OpenAI dashboard.
              </p>
            </div>
            
            <div className="flex justify-end space-x-3 mt-4">
              <button
                type="button"
                onClick={onClose}
                className={`px-4 py-2 rounded-md transition-colors duration-200 ${
                  isDarkMode 
                    ? 'bg-[rgb(50,50,50)] hover:bg-[rgb(60,60,60)] text-gray-200' 
                    : 'bg-[rgb(189,189,185)] hover:bg-[rgb(179,179,175)] text-gray-700'
                }`}
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isLoading || !apiKey.trim()}
                className={`px-4 py-2 rounded-md transition-colors duration-200 ${
                  isLoading || !apiKey.trim()
                    ? 'bg-blue-400 text-white opacity-70 cursor-not-allowed'
                    : 'bg-blue-500 hover:bg-blue-600 text-white'
                }`}
              >
                {isLoading ? 'Saving...' : 'Save Key'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ApiKeyDialog; 