import React, { useState, useEffect } from 'react';
import { Sun, Moon, Monitor, LogOut, Key, Eye, EyeOff } from 'lucide-react';
import { useTheme } from './ThemeContext';
import { useFont } from './FontContext';
import Select from 'react-select';
import { saveUserApiKey, getUserApiKey } from './chatModels';
import { auth } from './firebase';

const Settings = ({ userName, userEmail, hasSubscription, onLogout, onUpgrade }) => {
  const [activeSection, setActiveSection] = useState('general');
  const { theme, setTheme } = useTheme();
  const { currentFont, setCurrentFont } = useFont();
  const [apiKey, setApiKey] = useState('');
  const [apiKeyStatus, setApiKeyStatus] = useState('');
  const [showApiKey, setShowApiKey] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const fonts = [
    { name: 'PT Serif', className: 'font-serif' },
    { name: 'Inter', className: 'font-sans' },
    { name: 'Funnel Display', className: 'font-sans' },
    { name: 'Sour Gummy', className: 'font-sans' },
    { name: 'DynaPuff', className: 'font-sans' },
    { name: 'Yusei Magic', className: 'font-sans' },
  ];

  const fontOptions = fonts.map((font) => ({
    value: font.name,
    label: font.name,
  }));

  // Conditional styling for dark vs light mode
  const isDark = theme === 'dark';
  const isDarkMode = theme === 'dark' || (theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches);

  const darkBg = '#242424';
  const darkBgHover = '#1e1e1e';
  const lightBg = '#FFFFFF';
  const lightBgHover = '#F2F2F2';
  const darkText = '#CBD5E0';
  const lightText = '#4A5568';

  const customStyles = {
    // The container that wraps the control
    container: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? darkBg : lightBg,
      color: isDarkMode ? darkText : lightText,
    }),
    // The control is the clickable area that shows the current value
    control: (provided, state) => ({
      ...provided,
      backgroundColor: isDarkMode ? darkBg : lightBg,
      borderColor: isDarkMode ? '#242424' : '#CBD5E0',
      color: isDarkMode ? darkText : lightText,
      ':hover': {
        borderColor: isDarkMode ? '#718096' : '#A0AEC0',
      },
      boxShadow: state.isFocused ? (isDarkMode ? '0 0 0 1px #718096' : '0 0 0 1px #A0AEC0') : null,
    }),
    // The dropdown menu
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? darkBg : lightBg,
      zIndex: 9999,
    }),
    // Individual options in the list
    option: (provided, state) => ({
      ...provided,
      fontFamily: state.data.value,
      backgroundColor: state.isFocused
        ? isDarkMode ? darkBgHover : lightBgHover
        : isDarkMode ? darkBg : lightBg,
      color: isDarkMode ? darkText : lightText,
      cursor: 'pointer'
    }),
    // The currently selected value
    singleValue: (provided, state) => ({
      ...provided,
      fontFamily: state.data.value,
      color: isDarkMode ? darkText : lightText,
    }),
    // The placeholder text
    placeholder: (provided) => ({
      ...provided,
      color: isDarkMode ? '#718096' : '#A0AEC0',
    }),
    // The indicators (dropdown arrow, clear button, etc.)
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: isDarkMode ? darkText : lightText,
      ':hover': {
        color: isDarkMode ? '#FFFFFF' : '#2D3748',
      },
    }),
    // The value container holding the selected value
    valueContainer: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? darkBg : lightBg,
      color: isDarkMode ? darkText : lightText,
    }),
  };

  const handleUnsubscribe = () => {
    window.location.href = 'https://billing.stripe.com/p/login/eVa8xn3QngOZdEYcMM';
  };

  useEffect(() => {
    if (theme === 'system') {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const handleChange = () => {
        document.documentElement.classList.toggle('dark', mediaQuery.matches);
      };
      mediaQuery.addListener(handleChange);
      handleChange();
      return () => mediaQuery.removeListener(handleChange);
    }
  }, [theme]);

  // Fetch API key on component mount
  useEffect(() => {
    const fetchApiKey = async () => {
      if (auth.currentUser) {
        const key = await getUserApiKey(auth.currentUser.uid);
        if (key) {
          setApiKey(key);
          setInputValue(key);
          setApiKeyStatus('API key is set');
        }
      }
    };
    
    fetchApiKey();
  }, []);

  const handleSaveApiKey = async () => {
    setIsLoading(true);
    try {
      if (!inputValue.trim().startsWith('sk-')) {
        setApiKeyStatus('Error: Invalid API key format');
        setIsLoading(false);
        return;
      }

      const success = await saveUserApiKey(auth.currentUser.uid, inputValue);
      if (success) {
        setApiKey(inputValue);
        setApiKeyStatus('API key saved successfully');
      } else {
        setApiKeyStatus('Failed to save API key');
      }
    } catch (error) {
      console.error('Error saving API key:', error);
      setApiKeyStatus('Error: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteApiKey = async () => {
    setIsLoading(true);
    try {
      const success = await saveUserApiKey(auth.currentUser.uid, '');
      if (success) {
        setApiKey('');
        setApiKeyStatus('API key removed');
      } else {
        setApiKeyStatus('Failed to remove API key');
      }
    } catch (error) {
      console.error('Error removing API key:', error);
      setApiKeyStatus('Error: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const GeneralSection = () => (
    <div className="space-y-6">
      <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">General</h2>
      <div>
        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Name</p>
        <p className="mt-1 text-lg text-gray-900 dark:text-gray-100">{userName}</p>
      </div>
      <div>
        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Email</p>
        <p className="mt-1 text-lg text-gray-900 dark:text-gray-100">{userEmail}</p>
      </div>

      {/* Appearance Controls */}
      <div className="flex items-center justify-between py-4 border-t border-gray-200 dark:border-gray-700">
        <div>
          <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">Appearance</h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">Adjust the appearance of the interface</p>
        </div>
        <div className="flex space-x-2">
          <button
            onClick={() => setTheme('light')}
            className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${
              theme === 'light'
                ? 'bg-blue-500 text-white'
                : 'text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600 dark:hover:bg-gray-600'
            }`}
          >
            <Sun className="mr-2" size={16} />
            Light
          </button>
          <button
            onClick={() => setTheme('dark')}
            className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${
              theme === 'dark'
                ? 'bg-blue-500 text-white'
                : 'text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600 dark:hover:bg-gray-600'
            }`}
          >
            <Moon className="mr-2" size={16} />
            Dark
          </button>
          <button
            onClick={() => setTheme('system')}
            className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${
              theme === 'system'
                ? 'bg-blue-500 text-white'
                : 'text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600 dark:hover:bg-gray-600'
            }`}
          >
            <Monitor className="mr-2" size={16} />
            System
          </button>
        </div>
      </div>

      {/* Font Selection using React-Select */}
      <div className="flex items-center justify-between py-4 border-t border-gray-200 dark:border-gray-700">
        <div>
          <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">Font</h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">Choose your preferred font</p>
        </div>
        <div className="relative inline-block" style={{ width: 200 }}>
          <Select
            value={fontOptions.find((opt) => opt.value === currentFont)}
            onChange={(selected) => setCurrentFont(selected.value)}
            options={fontOptions}
            styles={customStyles} // Use the new dark mode-aware styles
            classNamePrefix="font-select"
            menuPlacement="auto"
          />
        </div>
      </div>

      <div className="flex items-center justify-between py-4 border-t border-gray-200 dark:border-gray-700">
        <div>
          <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">Log out of my account</h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">Terminate your current session on this device</p>
        </div>
        <button
          onClick={onLogout}
          className="flex items-center px-4 py-2 text-sm font-medium text-red-600 bg-white border border-red-600 rounded-md hover:bg-red-50 dark:bg-gray-700 dark:text-red-400 dark:border-red-400 dark:hover:bg-gray-600"
        >
          <LogOut className="mr-2" size={16} />
          Log Out
        </button>
      </div>
    </div>
  );

  const ApiKeysSection = () => (
    <div className="space-y-6">
      <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">API Keys</h2>
      <div className="bg-[rgb(209,209,205)] dark:bg-[rgb(36,36,36)] rounded-lg p-6 shadow-sm border border-[rgb(219,219,215)] dark:border-[rgb(40,40,40)]">
        <h3 className="text-lg font-medium text-gray-800 dark:text-white mb-4">OpenAI API Key</h3>
        <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
          Add your OpenAI API key to use models that require it. Your key is stored securely and never shared.
        </p>
        
        <div className="relative mb-4">
          <div className="flex">
            <input
              type={showApiKey ? "text" : "password"}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              placeholder="sk-..."
              className="w-full px-4 py-2 border border-[rgb(199,199,195)] dark:border-[rgb(50,50,50)] rounded-md bg-[rgb(229,229,225)] dark:bg-[rgb(29,29,29)] text-gray-800 dark:text-white focus:ring-blue-500 focus:border-blue-500"
            />
            <button
              type="button"
              onClick={() => setShowApiKey(!showApiKey)}
              className="absolute right-2 top-2 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
            >
              {showApiKey ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          </div>
        </div>
        
        {apiKeyStatus && (
          <p className={`text-sm mb-4 ${apiKeyStatus.includes('success') || apiKeyStatus === 'API key is set' ? 'text-green-500 dark:text-green-400' : apiKeyStatus.includes('Error') || apiKeyStatus.includes('Failed') ? 'text-red-500 dark:text-red-400' : 'text-blue-500 dark:text-blue-400'}`}>
            {apiKeyStatus}
          </p>
        )}
        
        <div className="flex space-x-4">
          <button
            onClick={() => {
              handleSaveApiKey();
            }}
            disabled={isLoading || !inputValue || (apiKey && inputValue === apiKey)}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 dark:focus:ring-offset-gray-800"
          >
            {isLoading ? 'Saving...' : apiKey ? 'Update Key' : 'Save Key'}
          </button>
          
          {apiKey && (
            <button
              onClick={handleDeleteApiKey}
              disabled={isLoading}
              className="px-4 py-2 text-red-600 bg-white border border-red-600 rounded-md hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:opacity-50 dark:bg-gray-700 dark:text-red-400 dark:border-red-400 dark:hover:bg-gray-600 dark:focus:ring-offset-gray-800"
            >
              {isLoading ? 'Removing...' : 'Remove Key'}
            </button>
          )}
        </div>
      </div>
      
      <div className="bg-yellow-50 dark:bg-yellow-900/30 border border-yellow-200 dark:border-yellow-700 rounded-lg p-4 mt-6">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800 dark:text-yellow-200">Important information</h3>
            <div className="mt-2 text-sm text-yellow-700 dark:text-yellow-300">
              <p>
                Your API key is stored securely in our database. It will only be used when you select models that require your own API key.
                You will be charged by OpenAI according to their pricing when using these models.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const SubscriptionSection = () => (
    <div className="space-y-6">
      <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">Subscription</h2>
      <div className="bg-gradient-to-br from-blue-400 to-purple-500 text-white rounded-lg p-6">
        <h3 className="text-2xl font-bold mb-2">Get unlimited access to ArrowAI</h3>
        <p className="mb-4">Get access to the premium AI from OpenAI, Anthropic, Google, and Perplexity in one place.</p>
        {hasSubscription ? (
          <button onClick={handleUnsubscribe} className="px-4 py-2 text-sm font-medium bg-gray-300 text-gray-600 rounded-md">
            Unsubscribe
          </button>
        ) : (
          <button onClick={onUpgrade} className="px-4 py-2 text-sm font-medium bg-white text-purple-600 rounded-md hover:bg-gray-50">
            Upgrade to Pro
          </button>
        )}
      </div>
    </div>
  );

  return (
    <div className="flex justify-center min-h-screen bg-transparent">
      <div className="w-full max-w-4xl bg-transparent dark:bg-transparent overflow-hidden pt-16">
        <div className="flex">
          <div className="w-1/4 bg-transparent dark:bg-transparent p-8">
            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200 mb-6">Settings</h2>
            <ul className="space-y-2">
              <li>
                <button
                  onClick={() => setActiveSection('general')}
                  className={`w-full text-left px-4 py-2 rounded-md ${
                    activeSection === 'general'
                      ? 'bg-blue-500 text-white'
                      : 'text-gray-600 dark:text-gray-300 hover:bg-[rgb(204,204,201)] dark:hover:bg-gray-600'
                  }`}
                >
                  General
                </button>
              </li>
              <li>
                <button
                  onClick={() => setActiveSection('apikeys')}
                  className={`w-full text-left px-4 py-2 rounded-md ${
                    activeSection === 'apikeys'
                      ? 'bg-blue-500 text-white'
                      : 'text-gray-600 dark:text-gray-300 hover:bg-[rgb(204,204,201)] dark:hover:bg-gray-600'
                  }`}
                >
                  <div className="flex items-center">
                    API Keys
                  </div>
                </button>
              </li>
              <li>
                <button
                  onClick={() => setActiveSection('subscription')}
                  className={`w-full text-left px-4 py-2 rounded-md ${
                    activeSection === 'subscription'
                      ? 'bg-blue-500 text-white'
                      : 'text-gray-600 dark:text-gray-300 hover:bg-[rgb(204,204,201)] dark:hover:bg-gray-600'
                  }`}
                >
                  Subscription
                </button>
              </li>
            </ul>
          </div>
          <div className="w-3/4 p-8">
            {activeSection === 'general' && <GeneralSection />}
            {activeSection === 'apikeys' && <ApiKeysSection />}
            {activeSection === 'subscription' && <SubscriptionSection />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
