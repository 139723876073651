import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithGoogle, signInWithApple, signUpWithEmail, signInWithEmail } from './firebase';
import { ThemeProvider } from './ThemeContext';
import { motion, AnimatePresence } from 'framer-motion';

const GoogleLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
      <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/>
      <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/>
      <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/>
      <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/>
    </svg>
);
  
const AppleLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="24px" height="24px">
      <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
    </svg>
);

const SignInScreen = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const [isSignUp, setIsSignUp] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });
    const [isFormValid, setIsFormValid] = useState(false);
    
    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    useEffect(() => {
        if (isSignUp) {
            setIsFormValid(
                validateEmail(email) &&
                password.length >= 4 &&
                password === confirmPassword &&
                fullName.trim() !== ''
            );
        } else {
            setIsFormValid(
                validateEmail(email) &&
                password.length >= 4
            );
        }
    }, [email, password, confirmPassword, fullName, isSignUp]);

    const showAlert = (message, type) => {
        setAlert({ show: true, message, type });
        setTimeout(() => setAlert({ show: false, message: '', type: '' }), 5000);
    };

    const handleAuthentication = async (authFunction, ...args) => {
        try {
            await authFunction(...args);
            showAlert('Successfully authenticated!', 'success');
            navigate('/chat');
        } catch (error) {
            showAlert(`Authentication error: ${error.message}`, 'error');
        }
    };

    const handleGoogleSignIn = () => handleAuthentication(signInWithGoogle);
    const handleAppleSignIn = () => handleAuthentication(signInWithApple);

    const handleEmailAuth = async (e) => {
        e.preventDefault();
        if (isSignUp) {
            if (password !== confirmPassword) {
                showAlert('Passwords don\'t match', 'error');
                return;
            }
            await handleAuthentication(signUpWithEmail, email, password, fullName);
        } else {
            await handleAuthentication(signInWithEmail, email, password);
        }
    };

    return (
        <ThemeProvider>
          <div className="flex items-center justify-center min-h-screen w-screen bg-[rgb(229,229,225)] dark:bg-[rgb(36,36,36)] p-4 font-semibold overflow-auto relative">
            <div className="absolute inset-0 overflow-hidden">
              <motion.div 
                className="absolute top-[-100px] left-[-100px] w-[500px] h-[500px] rounded-full bg-gradient-to-br from-purple-500 to-purple-800 opacity-60 blur-[80px]"
                animate={{ 
                  x: [0, 50, -30, 0],
                  y: [0, -30, 50, 0],
                  scale: [1, 1.1, 0.9, 1]
                }}
                transition={{ 
                  repeat: Infinity, 
                  duration: 12,
                  ease: "easeInOut"
                }}
              />
              <motion.div 
                className="absolute bottom-[-100px] right-[-100px] w-[400px] h-[400px] rounded-full bg-gradient-to-br from-blue-400 to-purple-600 opacity-60 blur-[80px]"
                animate={{ 
                  x: [0, -40, 30, 0],
                  y: [0, 40, -40, 0],
                  scale: [1, 0.9, 1.1, 1]
                }}
                transition={{ 
                  repeat: Infinity, 
                  duration: 10,
                  ease: "easeInOut"
                }}
              />
              <motion.div 
                className="absolute top-1/2 right-[10%] w-[300px] h-[300px] rounded-full bg-gradient-to-br from-pink-500 to-purple-500 opacity-60 blur-[80px]"
                animate={{ 
                  x: [0, 30, -20, 0],
                  y: [0, -20, 30, 0],
                  scale: [1, 1.2, 0.8, 1]
                }}
                transition={{ 
                  repeat: Infinity, 
                  duration: 8,
                  ease: "easeInOut"
                }}
              />
              <motion.div 
                className="absolute bottom-[20%] left-[15%] w-[250px] h-[250px] rounded-full bg-gradient-to-br from-indigo-400 to-cyan-400 opacity-50 blur-[80px]"
                animate={{ 
                  x: [0, -25, 15, 0],
                  y: [0, 25, -15, 0],
                  scale: [1, 0.85, 1.15, 1]
                }}
                transition={{ 
                  repeat: Infinity, 
                  duration: 14,
                  ease: "easeInOut"
                }}
              />
            </div>
            
            <motion.div 
              className="w-full max-w-md text-center max-h-screen overflow-y-auto py-8 relative z-10"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <AnimatePresence>
                {alert.show && (
                  <motion.div 
                    className={`fixed top-4 left-1/2 transform -translate-x-1/2 p-4 rounded-full ${alert.type === 'success' ? 'bg-green-500' : 'bg-red-500'} text-white font-bold backdrop-blur-md shadow-lg`}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                  >
                    {alert.message}
                  </motion.div>
                )}
              </AnimatePresence>
              
              <motion.div 
                className="flex items-center justify-center mb-6"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}
              >
                <img 
                  src="/app_icon.png" 
                  alt="Arrow.AI Logo" 
                  className="h-12 w-12 rounded-lg mr-3" 
                />
                <h1 className="text-4xl font-bold dark:text-gray-200">
                  <span className="text-gray-900 dark:text-gray-200">Arrow</span>
                  <span className="bg-gradient-to-r from-pink-500 via-purple-600 to-blue-400 bg-clip-text text-transparent">.AI</span>
                </h1>
              </motion.div>
              
              <motion.p 
                className="text-lg mb-8 font-semibold dark:text-gray-300"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3, duration: 0.5 }}
              >
                Access cutting-edge AI from OpenAI, Anthropic, Google, Perplexity, and more.<br></br> Start now for free!
              </motion.p>
    
              <motion.div
                className="space-y-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.5 }}
              >
                <motion.button 
                  onClick={handleGoogleSignIn} 
                  className="w-full bg-white text-black border border-gray-300 rounded-full py-3 mb-4 flex items-center justify-center font-bold transition-all duration-200 hover:scale-105 hover:shadow-lg"
                  whileHover={{ y: -3, boxShadow: "0 10px 25px rgba(0, 0, 0, 0.1)" }}
                  whileTap={{ scale: 0.98 }}
                >
                  <GoogleLogo />
                  <span className="ml-2">Continue with Google</span>
                </motion.button>
    
                <motion.button 
                  onClick={handleAppleSignIn} 
                  className="w-full bg-white text-black border border-gray-300 rounded-full py-3 mb-4 flex items-center justify-center font-bold transition-all duration-200 hover:scale-105 hover:shadow-lg"
                  whileHover={{ y: -3, boxShadow: "0 10px 25px rgba(0, 0, 0, 0.1)" }}
                  whileTap={{ scale: 0.98 }}
                >
                  <AppleLogo />
                  <span className="ml-2">Continue with Apple</span>
                </motion.button>
              </motion.div>
    
              <motion.div 
                className="text-center text-gray-500 my-6 font-bold relative flex items-center justify-center"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.5 }}
              >
                <div className="absolute left-0 right-0 h-[1px] bg-gray-300 dark:bg-gray-700"></div>
                <span className="bg-[rgb(229,229,225)] dark:bg-[rgb(36,36,36)] px-4 relative z-10">or</span>
              </motion.div>
    
              <motion.form 
                onSubmit={handleEmailAuth} 
                className="w-full space-y-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.5 }}
              >
                <AnimatePresence>
                  {isSignUp && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <input
                        type="text"
                        placeholder="Full Name"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        className="w-full p-3 pl-4 rounded-full border border-gray-300 font-semibold dark:bg-[rgba(36,36,36,0.8)] dark:text-white dark:border-gray-600 backdrop-blur-sm transition-all duration-200 focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
                
                <input
                  type="email"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full p-3 pl-4 rounded-full border border-gray-300 font-semibold dark:bg-[rgba(36,36,36,0.8)] dark:text-white dark:border-gray-600 backdrop-blur-sm transition-all duration-200 focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
                
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full p-3 pl-4 rounded-full border border-gray-300 font-semibold dark:bg-[rgba(36,36,36,0.8)] dark:text-white dark:border-gray-600 backdrop-blur-sm transition-all duration-200 focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
                
                <AnimatePresence>
                  {isSignUp && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <input
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="w-full p-3 pl-4 rounded-full border border-gray-300 font-semibold dark:bg-[rgba(36,36,36,0.8)] dark:text-white dark:border-gray-600 backdrop-blur-sm transition-all duration-200 focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
                
                <motion.button 
                  type="submit" 
                  className={`w-full ${isFormValid ? 'bg-gradient-to-r from-purple-600 to-blue-500 hover:scale-105' : 'bg-gray-500 cursor-not-allowed'} text-white rounded-full py-3 font-bold transition-all duration-200 shadow-md`}
                  disabled={!isFormValid}
                  whileHover={isFormValid ? { y: -3, boxShadow: "0 10px 25px rgba(114, 9, 183, 0.3)" } : {}}
                  whileTap={isFormValid ? { scale: 0.98 } : {}}
                >
                  {isSignUp ? 'Sign Up' : 'Log In'}
                </motion.button>
              </motion.form>
    
              <motion.button 
                onClick={() => {
                  setIsSignUp(!isSignUp);
                  setIsFormValid(false);
                  setEmail('');
                  setPassword('');
                  setConfirmPassword('');
                  setFullName('');
                }} 
                className="text-gray-800 dark:text-gray-200 hover:text-purple-600 dark:hover:text-purple-400 w-full text-center font-bold transition-colors duration-200 mt-6"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.7, duration: 0.5 }}
              >
                {isSignUp ? 'Already have an account? Sign In' : 'Don\'t have an account? Sign Up'}
              </motion.button>
    
              <motion.p 
                className="text-sm text-gray-500 mt-6 text-center font-semibold"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.8, duration: 0.5 }}
              >
                By continuing, you are agreeing to Arrow.AI's Terms of Service and Privacy Policy.
              </motion.p>
            </motion.div>
          </div>
        </ThemeProvider>
    );
};

export default SignInScreen;